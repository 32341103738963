<template>
  <v-row class="material-preview">
    <v-col
      cols="12"
      md="9"
    >
      <material-editable :data="materialData">
      </material-editable>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col
      v-if="materialData.id"
      cols="12"
      md="3"
    >
      <v-card class="mb-6">
        <v-card-text>
          <v-btn
            v-if="!materialData.published"
            color="primary"
            class="mb-3"
            block
            @click="publish(materialData)"
          >
            <v-icon
              class="me-2"
              left
            >
              {{ icons.mdiShare }}
            </v-icon>
            <span>Publish</span>
          </v-btn>

          <v-btn
            v-else
            color="secondary"
            class="mb-1"
            block
            @click="unpublish(materialData)"
          >
            <v-icon
              class="me-2"
              left
            >
              {{ icons.mdiShareOff }}
            </v-icon>
            <span>Unpublish</span>
          </v-btn>
          <div
            v-if="materialData.published"
            class="mb-3"
          >
            Published on: {{ humanReadable(materialData.published_on) }}
          </div>

          <v-btn
            v-if="!materialData.sold"
            color="success"
            block
            @click="sold(materialData)"
          >
            <v-icon
              class="me-2"
              left
            >
              {{ icons.mdiCurrencyUsd }}
            </v-icon>
            <span>Sold</span>
          </v-btn>

          <v-btn
            v-else
            color="secondary"
            class="mb-1"
            block
            @click="unsold(materialData)"
          >
            <v-icon
              class="me-2"
              left
            >
              {{ icons.mdiCurrencyUsdOff }}
            </v-icon>
            <span>Unsold</span>
          </v-btn>
          <div
            v-if="materialData.sold"
          >
            Published on: {{ humanReadable(materialData.sold_on) }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- invoice send drawer
    <invoice-sidebar-send-invoice v-model="isInvoiceSendSidebarActive"></invoice-sidebar-send-invoice>
    -->

    <!-- invoice add payment drawer
    <invoice-sidebar-add-payment v-model="isAddPaymentSidebarActive"></invoice-sidebar-add-payment>
    -->
  </v-row>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { mdiCurrencyUsd, mdiCurrencyUsdOff, mdiShare, mdiShareOff } from '@mdi/js'
import { onUnmounted, ref } from 'vue'
import moment from 'moment'

// import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'
// import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
import MaterialEditable from '../MaterialEditable.vue'
import materialStoreModule from '../materialStoreModule'

export default {
  components: {
    MaterialEditable,

    // InvoiceSidebarSendInvoice,
    // InvoiceSidebarAddPayment,
  },
  setup() {
    const MATERIAL_APP_STORE_MODULE_NAME = 'app-material'
    const userData = JSON.parse(localStorage.getItem('userData'))

    // Register module
    if (!store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(MATERIAL_APP_STORE_MODULE_NAME, materialStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) store.unregisterModule(MATERIAL_APP_STORE_MODULE_NAME)
    })


    const materialData = ref({})

    const unpublish = async item => {
      await store
        .dispatch('app-material/updateMaterial', { itemId: item.id, payload: { published: false } })
        .then(() => {
          item.published = false

          // Update local storage
          const localMaterials = JSON.parse(localStorage.getItem('materials-list'))
          const itemIndex = localMaterials.findIndex(m => m.id === item.id)
          localMaterials[itemIndex].published = false

          localStorage.setItem('materials-list', JSON.stringify(localMaterials))
        })
    }

    const publish = async item => {
      await store
        .dispatch('app-material/updateMaterial', { itemId: item.id, payload: { published: true } })
        .then(() => {
          item.published = true
          item.published_on = moment().format('MM/DD/yyyy hh:mma')

          // Update local storage
          const localMaterials = JSON.parse(localStorage.getItem('materials-list'))
          const itemIndex = localMaterials.findIndex(m => m.id === item.id)
          localMaterials[itemIndex].published = true
          localMaterials[itemIndex].published_on = moment().format('MM/DD/yyyy hh:mma')

          localStorage.setItem('materials-list', JSON.stringify(localMaterials))
        })
    }

    const unsold = async item => {
      await store.dispatch('app-material/updateMaterial', { itemId: item.id, payload: { sold: false } }).then(() => {
        item.sold = false

        // Update local storage
        const localMaterials = JSON.parse(localStorage.getItem('materials-list'))
        const itemIndex = localMaterials.findIndex(m => m.id === item.id)
        localMaterials[itemIndex].sold = false

        localStorage.setItem('materials-list', JSON.stringify(localMaterials))
      })
    }

    const sold = async item => {
      await store.dispatch('app-material/updateMaterial', { itemId: item.id, payload: { sold: true } }).then(() => {
        item.sold = true
        item.sold_on = moment().format('MM/DD/yyyy hh:mma')

        // Update local storage
        const localMaterials = JSON.parse(localStorage.getItem('materials-list'))
        const itemIndex = localMaterials.findIndex(m => m.id === item.id)
        localMaterials[itemIndex].sold = true
        localMaterials[itemIndex].sold_on = moment().format('MM/DD/yyyy hh:mma')

        localStorage.setItem('materials-list', JSON.stringify(localMaterials))
      })
    }

    const humanReadable = dtobj => {
      if (dtobj.seconds !== undefined) {
        return moment(dtobj.seconds * 1000).format('MM/DD/yyyy hh:mma')
      }

      return dtobj
    }

    if (router.currentRoute.params.id) {
      let loadFromFirebase = true
      const matId = router.currentRoute.params.id
      const localMaterials = JSON.parse(localStorage.getItem('materials-list'))
      if (localMaterials !== undefined && localMaterials !== null) {
        const itemIndex = localMaterials.findIndex(m => m.id === matId)
        if (itemIndex >= 0) {
          materialData.value = localMaterials[itemIndex]
          loadFromFirebase = false
        }
      }

      if (loadFromFirebase) {
        store
          .dispatch('app-material/getMaterial')
          .then(response => {
            // console.log(response.data())
            const material = response.data()
            material.id = router.currentRoute.params.id
            materialData.value = material
          })
          .catch(error => {
            console.log(error)
            if (error.response.status === 404) {
              materialData.value = {}
            }
          })
      }
    } else {
      materialData.value["email"] = userData.user.email
      store
        .dispatch('app-material/getUserById', userData.user.uid)
        .then(response => {
          materialData.value["phone"] = response.data().phone
          console.log(addData)
        })
        .catch(error => {
          console.log(error)
        })
    }

    return {
      materialData,
      publish,
      unpublish,
      sold,
      unsold,
      humanReadable,

      // Icons
      icons: {
        mdiShare,
        mdiCurrencyUsd,
        mdiShareOff,
        mdiCurrencyUsdOff,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';
</style>
