<template>
  <v-card
    v-if="data"
    class="material-editable"
  >
    <v-dialog
      v-model="imageDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="headline">
          Image of {{ materialType }}
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-space-around dense center-aligned">
            <img
              class="preview"
              :src="`${materialTypeImage}`"
            >
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="success"
            @click="imageDialogVisible = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-form
      ref="materialForm"
      class="multi-col-validation"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="data.title"
            label="Name/Title"
            outlined
            dense
            placeholder="Material Name/Title"
            hide-details="auto"
            :rules="[validators.required]"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="materialType"
            outlined
            dense
            :items="itemTypes"
            item-text="description"
            item-value="itemSubtype"
            placeholder="Choose a Material Type"
            label="Material Type"
            hide-details="auto"
            return-object
            @change="selectedMaterial"
          ></v-select>
          <div class="image-preview" v-if="materialTypeImage !== null">
            <h4>See Example</h4>
            <img :src="`${materialTypeImage}`" @click="imageDialogVisible = true">
          </div>
          <div v-if="showOtherField" class="pt-2">
            <v-text-field
              v-model="otherSubtypeField"
              label="Other material type"
              outlined
              dense
              placeholder="Enter a custom material type"
              hide-details="auto"
              :rules="[validators.required]"
              @keyup="modifyOtherSubtype"
            ></v-text-field>
          </div>
        </v-col>

        <v-col cols="12" md="6">
          <div class="v-input v-input--hide-details v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder">
            <div class="v-input__control">
              <div class="v-input__slot">
                <fieldset aria-hidden="true">
                  <legend style="width: 55px;">
                    <span class="notranslate">&ZeroWidthSpace;</span>
                  </legend>
                </fieldset>
                <div class="v-text-field__slot">
                  <label
                    class="v-label v-label--active theme--light"
                    style="left: 0px; right: auto; position: absolute;"
                  >Location</label>
                  <Autocomplete
                    placeholder="Enter location"
                    :value="data.address"
                    @place_changed="setPlace"
                  >
                  </Autocomplete>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-menu
            v-model="dateField"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="data.need_by"
                label="Date need by"
                outlined
                dense
                :prepend-icon="icons.mdiCalendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="data.need_by"
              color="primary"
              @input="dateField = false"
            ></v-date-picker>
          </v-menu>
          <!--
          <v-text-field
            v-model="dateNeedBy"
            label="Date need by"
            outlined
            dense
            :prepend-icon="icons.mdiCalendar"
            readonly
            @click="showDatePicker=true;"
          ></v-text-field>
          <v-dialog
            ref="dialog"
            v-model="showDatePicker"
            :return-value.sync="dateNeedBy"
            persistent
            width="290px"
          >
            <v-date-picker
              v-model="dateNeedBy"
              color="primary"
              :min="new Date().toISOString().substring(0, 10)"
            >
              <v-btn
                text
                color="primary"
                @click="modal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(dateNeedBy)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
          -->
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="data.amount"
            label="System of Units"
            outlined
            dense
            placeholder="Amount of Material"
            hide-details="auto"
            :rules="[validators.required]"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
        <v-select
            v-model="data.metric"
            outlined
            dense
            :items="metricTypes"
            item-text="description"
            item-value="itemType"
            placeholder="Choose a Metric"
            label="Number Unit"
            hide-details="auto"
            return-object
            @change="selectedMetric"
            :rules="[validators.required]"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
        <v-select
            v-model="data.post_type"
            outlined
            dense
            :items="postTypes"
            item-text="description"
            item-value="post_type"
            placeholder="Choose a Post Type"
            label="Post Type"
            hide-details="auto"
            return-object
            @change="selectedPosting"
            :rules="[validators.required]"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="data.price"
            label="Price (in $)"
            outlined
            dense
            placeholder="0.99"
            hide-details="auto"
            :rules="[validators.required]"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
        <v-select
            v-model="data.machine_option"
            outlined
            dense
            :items="machineOptions"
            item-text="description"
            item-value="itemType"
            placeholder="Choose a Machine Option"
            label="Machine Options"
            hide-details="auto"
            return-object
            @change="selectedMachine"
            :rules="[validators.required]"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
        <v-select
            v-model="data.trucking_option"
            outlined
            dense
            :items="truckOptions"
            item-text="description"
            item-value="itemType"
            placeholder="Choose a Trucking Option"
            label="Trucking Options"
            hide-details="auto"
            return-object
            @change="selectedTrucking"
            :rules="[validators.required]"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
        <v-select
            v-model="data.preferred_contact"
            outlined
            dense
            :items="contactTypes"
            item-text="description"
            item-value="preferred_contact"
            placeholder="How to be contacted?"
            label="How to be contacted?"
            hide-details="auto"
            return-object
            @change="selectedContact"
            :rules="[validators.required]"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          v-if="data.preferred_contact == 'both' || data.preferred_contact == 'phone'"
          :md="data.preferred_contact == 'both' ? '3' : '6'"
        >
          <v-text-field
            v-model="data.phone"
            label="Phone Number"
            outlined
            dense
            placeholder="Phone Number"
            hide-details="auto"
            :rules="[validators.required]"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          v-if="data.preferred_contact == 'both' || data.preferred_contact == 'email'"
          :md="data.preferred_contact == 'both' ? '3' : '6'"
        >
          <v-text-field
            v-model="data.email"
            label="Email"
            outlined
            dense
            placeholder="email@domain.com"
            hide-details="auto"
            :rules="[validators.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-textarea
            v-model="data.description"
            label="Description"
            outlined
            dense
            auto-grow
            hide-details
            placeholder="Description of the Material"
          ></v-textarea>
        </v-col>

        <h3>
          Upload photos (up to 3)
        </h3>
        <v-col
          cols="12"
          style="display: inline-flex"
        >
          <v-col
            v-for="(img, idx) in imageContainer"
            :key="img"
            cols="4"
            md="4"
          >
            <app-card-actions
              @removed="removeImage(idx)"
            >
              <template slot="title">
                Image #{{ idx + 1 }}
              </template>
              <v-card-text>
                <img
                  class="preview"
                  height="200"
                  :src="img"
                >
              </v-card-text>
            </app-card-actions>
          </v-col>
        </v-col>

        <v-btn
          color="secondary"
          :disabled="checkImages(data)"
          @click="fetchPhotos"
        >
          Browse for photos
        </v-btn>
        <input
          ref="inputFile"
          type="file"
          style="display: none"
          accept="image/*"
          @change="previewImages"
        >

        <v-col cols="12" v-if="!inProgress">
          <v-btn
            v-if="data.id"
            color="primary"
            @click="update(data)"
          >
            Update
          </v-btn>
          <v-btn
            v-else
            :disabled="data.title === null || data.title === undefined || data.title.length === 0"
            color="primary"
            @click="add(data)"
          >
            Post
          </v-btn>
        </v-col>
        <v-col cols="12" v-else>
          <VProgressCircular
            indeterminate
            color="primary"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import store from '@/store'
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import { useRouter } from '@core/utils'
import { required, requiredSelect } from '@core/utils/validation'
import { mdiClose, mdiCogOutline, mdiCalendar } from '@mdi/js'
import themeConfig from '@themeConfig'
import { onUnmounted, ref } from 'vue'
import Autocomplete from 'vue3-google-maps/dist/components/autocomplete.vue'
import materialStoreModule from './materialStoreModule'
import moment from 'moment'

export default {
  components: { Autocomplete, AppCardActions },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // ————————————————————————————————————
    //* ——— Store Registration
    // ————————————————————————————————————
    const { router } = useRouter()
    const MATERIAL_APP_STORE_MODULE_NAME = 'app-material'

    // Register module
    if (!store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(MATERIAL_APP_STORE_MODULE_NAME, materialStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) store.unregisterModule(MATERIAL_APP_STORE_MODULE_NAME)
    })
    const isIssueDateMenuOpen = ref(false)
    const isDueDateMenuOpen = ref(false)
    const clients = ref([])
    const inProgress = ref(false)
    const errorMessages = ref([])
    const materialForm = ref(null)
    const images = ref([])
    const inputFile = ref(null)
    const imageContainer = ref([])
    const materialType = ref(null)
    const materialTypeResults = ref([])
    const dateNeedBy = new Date().toISOString().substring(0, 10)
    const showDatePicker = ref(false)
    const materialTypeImage = ref(null)
    const tmpImages = ref([])
    const materialPicked = ref(null)
    const imageDialogVisible = ref(false)
    const dateField = ref(false)
    const showOtherField = ref(false)
    const otherSubtypeField = ref(null)

    const showPhone = ref(false)
    const showEmail = ref(false)

    /* store.dispatch('app-invoice/fetchClients').then(response => {
          clients.value = response.data
        }) */
    const metricTypes = [
      {
        itemType: 'tons',
        description: 'Tons',
      },
      {
        itemType: 'yards',
        description: 'Yards',
      },
      /*{
        itemType: 'yards_cubed',
        description: 'Yards Cubed',
      },*/
      {
        itemType: 'meters',
        description: 'Meters',
      },
    ]

    const truckOptions = [
      {
        itemType: 'truck_available',
        description: 'I have a truck available to deliver material',
      },
      {
        itemType: 'truck_required',
        description: 'I require a truck to deliver material',
      },
    ]

    const machineOptions = [
      {
        itemType: 'machine_available',
        description: 'I have a machine on site to load material',
      },
      {
        itemType: 'machine_required',
        description: 'I do not have a machine on site to load the material',
      },
    ]

    let itemTypes = [
      {
        itemType: '',
        description: 'Reset',
      },
      {
        itemType: 'Asphalt',
        itemSubtype: 'Asphalt',
        description: 'Asphalt',
        image: require('@/assets/images/misc/materials/asphalt.jpeg'),
      },
      {
        itemType: 'Asphalt',
        description: '--- Recycled ashphalt',
        itemSubtype: 'Recycled ashphalt',
        image: require('@/assets/images/misc/materials/recycled-asphalt.png'),
      },
      /*{
        itemType: 'Assorted mixed material',
        description: 'Assorted mixed material',
      },
      {
        description: '--- Contaminated mixed material',
        itemSubType: 'Contaminated mixed material',
        image: '',
      },*/
      {
        itemType: 'Bark mulch',
        itemSubtype: 'Bark mulch',
        description: 'Bark mulch',
        image: require('@/assets/images/misc/materials/bark-mulch.png'),
      },
      {
        itemType: 'Clay',
        itemSubtype: 'Clay',
        description: 'Clay',
        image: require('@/assets/images/misc/materials/clay.png'),
      },
      {
        itemType: 'Clay',
        description: '--- Clean Clay',
        itemSubtype: 'Clean Clay',
        image: require('@/assets/images/misc/materials/clay.png'),
      },
      {
        itemType: 'Clay',
        description: '--- Dirty contaminated clay material',
        itemSubtype: 'Dirty contaminated clay material',
        image: require('@/assets/images/misc/materials/contaminated-clay.png'),
      },
      {
        itemType: 'Clay',
        description: '--- Medium contaminated clay material',
        itemSubtype: 'Medium contaminated clay material',
        image: require('@/assets/images/misc/materials/contaminated-clay.png'),
      },
      {
        itemType: 'Concrete',
        itemSubtype: 'Concrete',
        description: 'Concrete',
        image: require('@/assets/images/misc/materials/concrete-rebar.png'),
      },
      {
        itemType: 'Concrete',
        description: '--- Concrete with rebar',
        itemSubtype: 'Concrete with rebar',
        image: require('@/assets/images/misc/materials/concrete-rebar.png'),
      },
      {
        itemType: 'Concrete',
        description: '--- Concrete without rebar',
        itemSubtype: 'Concrete without rebar',
        image: require('@/assets/images/misc/materials/concrete-wo-rebar.png'),
      },
      {
        itemType: 'Fill material',
        itemSubtype: 'Fill material',
        description: 'Fill material',
        image: require('@/assets/images/misc/materials/clean-fill-material.png'),
      },
      {
        itemType: 'Fill material',
        description: '--- Clean fill material',
        itemSubtype: 'Clean fill material',
        image: require('@/assets/images/misc/materials/clean-fill-material.png'),
      },
      {
        itemType: 'Fill material',
        description: '--- Dirty contaminated fill material',
        itemSubtype: 'Dirty contaminated fill material',
        image: require('@/assets/images/misc/materials/dirty-fill-material.jpeg'),
      },
      {
        itemType: 'Fill material',
        description: '--- Medium contaminated fill material',
        itemSubtype: 'Medium contaminated fill material',
        image: require('@/assets/images/misc/materials/medium-fill-material.jpeg'),
      },
      {
        itemType: 'Fractured clear rock',
        itemSubtype: 'Fractured clear rock',
        description: 'Fractured clear rock',
        image: require('@/assets/images/misc/materials/fractured-clear-rock-with-2-or-more-flat-sides.png'),
      },
      {
        itemType: 'Fractured clear rock',
        description: '--- 12mm clear fractured stone',
        itemSubtype: '12mm clear fractured stone',
        image: require('@/assets/images/misc/materials/12mm-fractured-stone.png'),
      },
      {
        itemType: 'Fractured clear rock',
        description: '--- 16mm clear fractured stone',
        itemSubtype: '16mm clear fractured stone',
        image: require('@/assets/images/misc/materials/16mm-fractured-stone.png'),
      },
      {
        itemType: 'Fractured clear rock',
        description: '--- 25mm clear fractured stone',
        itemSubtype: '25mm clear fractured stone',
        image: require('@/assets/images/misc/materials/25mm-fractured-stone.png'),
      },
      {
        itemType: 'Fractured clear rock',
        description: '--- 50mm clear fractured stone',
        itemSubtype: '50mm clear fractured stone',
        image: require('@/assets/images/misc/materials/50mm-fractured-stone.png'),
      },
      {
        itemType: 'Fractured clear rock',
        description: '--- 75mm clear fractured stone',
        itemSubtype: '75mm clear fractured stone',
        image: require('@/assets/images/misc/materials/75mm-fractured-stone.png'),
      },
      {
        itemType: 'Fractured clear rock',
        description: '--- 75-150mm clear fractured stone',
        itemSubtype: '75-150mm clear fractured stone',
        image: require('@/assets/images/misc/materials/75to150mm-fractured-stone.png'),
      },
      {
        itemType: 'Fractured clear rock',
        description: '--- Rip wrap - Large, fractured stone',
        itemSubtype: 'Rip wrap - Large, fractured stone',
        image: require('@/assets/images/misc/materials/rip-wrap-fractured-stone.png'),
      },
      {
        itemType: 'Fractured clear rock',
        description: '--- Wall Rock  with 2 or more flat sides',
        itemSubtype: 'Wall Rock  with 2 or more flat sides',
        image: require('@/assets/images/misc/materials/fractured-clear-rock-with-2-or-more-flat-sides.png'),
      },
      {
        itemType: 'Fractured rock compactible',
        itemSubtype: 'Fractured rock compactible',
        description: 'Fractured rock compactible',
        image: require('@/assets/images/misc/materials/fractured-rock-compactible-blast-bank.png'),
      },
      {
        itemType: 'Fractured rock compactible',
        description: '--- 6mm Crusher Chip',
        itemSubtype: '6mm Crusher Chip',
        image: require('@/assets/images/misc/materials/fractured-rock-compactable-6mm.png'),
      },
      {
        itemType: 'Fractured rock compactible',
        description: '--- 25mm Road Base (Compactable) Rock',
        itemSubtype: '25mm Road Base (Compactable) Rock',
        image: require('@/assets/images/misc/materials/fractured-rock-compactible-25mm.png'),
      },
      {
        itemType: 'Fractured rock compactible',
        description: '--- 75mm Road Base (Compactable) Rock',
        itemSubtype: '75mm Road Base (Compactable) Rock',
        image: require('@/assets/images/misc/materials/fractured-rock-compactible-75mm.png'),
      },
      {
        itemType: 'Fractured rock compactible',
        description: '--- 150mm Road Base (Compactable) Rock',
        itemSubtype: '150mm Road Base (Compactable) Rock',
        image: require('@/assets/images/misc/materials/fractured-rock-compactible-150mm.png'),
      },
      {
        itemType: 'Fractured rock compactible',
        description: '--- Blast Bank Run Fractured Stone',
        itemSubtype: 'Blast Bank Run Fractured Stone',
        image: require('@/assets/images/misc/materials/fractured-rock-compactible-blast-bank.png'),
      },
      {
        itemType: 'Lawn / Sod',
        itemSubtype: 'Lawn / Sod',
        description: 'Lawn / Sod',
        image: require('@/assets/images/misc/materials/clean-sod.png'),
      },
      {
        itemType: 'Lawn / Sod',
        description: '--- Contaminated Sod material',
        itemSubtype: 'Contaminated Sod material',
        image: require('@/assets/images/misc/materials/dirty-contaminated-sod.png'),
      },
      {
        itemType: 'Natural stone',
        itemSubtype: 'Natural stone',
        description: 'Natural stone',
        image: require('@/assets/images/misc/materials/larger-natural-stone.png'),
      },
      {
        itemType: 'Natural stone',
        description: '--- 5-10mm clear natural stone',
        itemSubtype: '5-10mm clear natural stone',
        image: require('@/assets/images/misc/materials/5to10mm-natural-stone.png'),
      },
      {
        itemType: 'Natural stone',
        description: '--- 5-14mm clear natural stone',
        itemSubtype: '5-14mm clear natural stone',
        image: require('@/assets/images/misc/materials/5to14mm-natural-stone.png'),
      },
      {
        itemType: 'Natural stone',
        description: '--- 25mm clear natural stone',
        itemSubtype: '25mm clear natural stone',
        image: require('@/assets/images/misc/materials/25mm-natural-stone.png'),
      },
      {
        itemType: 'Natural stone',
        description: '--- 50mm clear natural stone',
        itemSubtype: '50mm clear natural stone',
        image: require('@/assets/images/misc/materials/50mm-natural-stone.png'),
      },
      {
        itemType: 'Natural stone',
        description: '--- 75-150mm Clear Natural Stone',
        itemSubtype: '75-150mm Clear Natural Stone',
        image: require('@/assets/images/misc/materials/75to150mm-natural-stone.png'),
      },      
      {
        itemType: 'Natural stone',
        description: '--- Larger natural stone',
        itemSubtype: 'Larger natural stone',
        image: require('@/assets/images/misc/materials/larger-natural-stone.png'),
      },
      {
        itemType: 'Ogo grow',
        itemSubtype: 'Ogo grow',
        description: 'Ogo grow',
        image: require('@/assets/images/misc/materials/ogo-grow.jpeg'),
      },
      {
        itemType: 'Ogo grow',
        description: '--- Clean Ogo Grow',
        itemSubtype: 'Clean Ogo Grow',
        image: require('@/assets/images/misc/materials/ogo-grow.jpeg'),
      },
      {
        itemType: 'Ogo grow',
        description: '--- Contaminated Ogo Grow',
        itemSubtype: 'Contaminated Ogo Grow',
        image: require('@/assets/images/misc/materials/ogo-grow-contaminated.jpeg'),
      },
      {
        itemType: 'Pit run',
        itemSubtype: 'Pit run',
        description: 'Pit run',
        image: require('@/assets/images/misc/materials/3-pit-run.jpeg'),
      },
      {
        itemType: 'Pit run',
        description: '--- 3" minus pit run',
        itemSubType: '3" minus pit run',
        image: require('@/assets/images/misc/materials/3-pit-run.jpeg'),
      },
      {
        itemType: 'Pit run',
        description: '--- 6" minus pit run',
        itemSubType: '6" minus pit run',
        image: require('@/assets/images/misc/materials/6-pit-run.png'),
      },
      /*{
        itemType: 'Pit run',
        description: '--- Oversized pit run',
        itemSubType: 'Oversized pit run',
        image: '',
      },*/
      {
        itemType: 'Sand',
        itemSubtype: 'Sand',
        description: 'Sand',
        image: require('@/assets/images/misc/materials/natural-sand.jpeg'),
      },
      {
        itemType: 'Sand',
        description: '--- 3mm City spec manufactured sand',
        itemSubType: '3mm City spec manufactured sand',
        image: require('@/assets/images/misc/materials/3-city-sand.png'),
      },
      {
        itemType: 'Sand',
        description: '--- 3mm Eco manufactured bedding sand',
        itemSubType: '3mm Eco manufactured bedding sand',
        image: require('@/assets/images/misc/materials/3-eco-sand.png'),
      },
      {
        itemType: 'Sand',
        description: '--- 5mm natural sand',
        itemSubType: '5mm natural sand',
        image: require('@/assets/images/misc/materials/5-natural-sand.jpeg'),
      },
      {
        itemType: 'Sand',
        description: '--- 5mm natural sand, blended with 5% salt',
        itemSubType: '5mm natural sand, blended with 5% salt',
        image: require('@/assets/images/misc/materials/5-sand-salt-sand.png'),
      },
      {
        itemType: 'Sand',
        description: '--- C-33 Septic Sand',
        itemSubType: 'C-33 Septic Sand',
        image: require('@/assets/images/misc/materials/c-33-septic-sand.png'),
      },
      {
        itemType: 'Sand',
        description: '--- Hydro spec bedding sand',
        itemSubType: 'Hydro spec bedding sand',
        image: require('@/assets/images/misc/materials/hydro-sand.png'),
      },
      {
        itemType: 'Sand',
        description: '--- Natural sand',
        itemSubType: 'Natural sand',
        image: require('@/assets/images/misc/materials/natural-sand.jpeg'),
      },
      {
        itemType: 'Top Soil',
        itemSubtype: 'Top Soil',
        description: 'Top soil',
        image: require('@/assets/images/misc/materials/topsoil.jpeg'),
      },
      {
        itemType: 'Top Soil',
        description: '--- Contaminated Top Soil material',
        itemSubtype: 'Contaminated Top Soil material',
        image: require('@/assets/images/misc/materials/topsoil-contaminated.png'),
      },
      {
        itemType: 'Trees',
        itemSubtype: 'Trees',
        description: 'Trees',
        image: require('@/assets/images/misc/materials/trees-stumps-with-roots.jpeg'),
      },
      {
        itemType: 'Trees',
        description: '--- Brush',
        itemSubtype: 'Brush',
        image: require('@/assets/images/misc/materials/trees-brush.png'),
      },
      {
        itemType: 'Trees',
        description: '--- Tree limbs',
        itemSubtype: 'Tree limbs',
        image: require('@/assets/images/misc/materials/trees-limbs.png'),
      },
      {
        itemType: 'Trees',
        description: '--- Tree Stumps',
        itemSubtype: 'Tree Stumps',
        image: require('@/assets/images/misc/materials/trees-stumps.png'),
      },
      {
        itemType: 'Trees',
        description: '--- Tree Stumps with roots',
        itemSubtype: 'Tree Stumps with roots',
        image: require('@/assets/images/misc/materials/trees-stumps-with-roots.jpeg'),
      },
      {
        itemType: 'Wood',
        itemSubtype: 'Wood',
        description: 'Wood',
        image: require('@/assets/images/misc/materials/wood.png'),
      },
      {
        itemType: 'Other',
        description: 'Other',
        image: '',
      },
    ]

    const postTypes = [
      {
        post_type: 'selling',
        description: 'Getting Rid of Material',
      },
      {
        post_type: 'looking',
        description: 'Taking Material',
      },
    ]

    const contactTypes = [
      {
        preferred_contact: 'phone',
        description: 'By phone',
      },
      {
        preferred_contact: 'email',
        description: 'By email',
      },
      {
        preferred_contact: 'both',
        description: 'Both email & phone',
      },
    ]

    const modifyOtherSubtype = () => {
      console.log(otherSubtypeField.value)

      props.data.subtype = otherSubtypeField.value
      materialType.value = otherSubtypeField.value
    }

    const selectedMaterial = mtype => {
      if (mtype.itemSubtype === undefined && mtype.itemType === 'Other') {
        props.data.type = mtype.itemType
        showOtherField.value = true;
      } else {
        showOtherField.value = false;
        materialType.value = mtype.itemSubtype
        materialTypeImage.value = mtype.image
        props.data.type = mtype.itemType
        props.data.subtype = mtype.itemSubtype
      }
    }

    const selectedMetric = metType => {
      props.data.metric = metType.itemType
    }
    
    const selectedContact = contactType => {
      if (contactType.preferred_contact == 'both') {
        showPhone.value = true;
        showEmail.value = true;
      }
      if (contactType.preferred_contact == 'phone') {
        showPhone.value = true;
        showEmail.value = false;
      }
      if (contactType.preferred_contact == 'email') {
        showPhone.value = false;
        showEmail.value = true;
      }
      props.data.preferred_contact = contactType.preferred_contact
    }

    const selectedPosting = postType => {
      props.data.post_type = postType.post_type
    }

    const selectedMachine = machineType => {
      props.data.machine_option = machineType.itemType
    }

    const selectedTrucking = truckType => {
      props.data.trucking_option = truckType.itemType
    }

    const fetchNewMaterial = async itemId => {
      console.log("All done, fetching new materials")
      store
        .dispatch('app-material/getMaterial', itemId)
        .then(response => {
          // console.log(response.data())
          const material = response.data()
          material.id = itemId
          if (material.images === undefined || material.images === null) {
            material.images = []
          }

          let localMaterials = JSON.parse(localStorage.getItem('materials-list'))
          if (localMaterials === null || localMaterials === undefined) {
            localMaterials = [];
          }
          localMaterials.push(material)
          localStorage.setItem('materials-list', JSON.stringify(localMaterials))
          // router.push({ name: 'materials-edit', params: { id: itemId } })
          // location.href = `/materials/edit/${itemId}`
          location.href = `/materials/list`
        })
        .catch(error => {
          inProgress.value = false
          console.log(error)
        })
    }

    const uploadImage = async (index, onSuccess) => {
      inProgress.value = true

      console.log(`Uploading Image ${index} vs ${tmpImages.value.length}`)
      if (index < tmpImages.value.length) {
        await store
          .dispatch('app-material/uploadImage', { image: tmpImages.value[index], itemId: props.data.id })
          .then(response => {          
            if (props.data.images === undefined || props.data.images === null) {
              props.data.images = []
            }
            // Add to the array
            props.data.images.push(response)
            // Upload Next Image
            uploadImage(index + 1, onSuccess)
          })
          .catch(err => {
            inProgress.value = false
            console.log(err)
          })
      } else {
        console.log("Run on success")
        onSuccess()
      }
    }

    const updateDBImages = async (itemId, newMaterial) => {
      const payload = {
        "images": props.data.images
      }
      console.log("Updating DB w/ Image payload")
      console.log(payload)
      // Update DB
      await store
        .dispatch('app-material/updateMaterial', { itemId: itemId, payload })
        .then(() => {
          inProgress.value = false

          const localMaterials = JSON.parse(localStorage.getItem('materials-list'))
          const itemIndex = localMaterials.findIndex(m => m.id === props.data.id)
          localMaterials[itemIndex] = props.data

          console.log(localMaterials[itemIndex])

          localStorage.setItem('materials-list', JSON.stringify(localMaterials))
          // New Material
          if (newMaterial) {
            fetchNewMaterial(itemId)
          }
        })
        .catch(err => {
          inProgress.value = false
          console.log(err)
        })
    }

    const upMaterial = async(data, payload) => {
      await store
        .dispatch('app-material/updateMaterial', { itemId: data.id, payload })
        .then(() => {
          // Remove Local Date Time
          localStorage.removeItem('last-loaded');
          // Upload Image
          uploadImage(0, () => {
            if (props.data.images !== undefined && props.data.images !== null && props.data.images.length > 0) {
              updateDBImages(props.data.id, false) 
            } else {
              tmpImages.value = []
              const localMaterials = JSON.parse(localStorage.getItem('materials-list'))
              const itemIndex = localMaterials.findIndex(m => m.id === props.data.id)
              localMaterials[itemIndex] = props.data

              console.log(localMaterials[itemIndex])
          
              localStorage.setItem('materials-list', JSON.stringify(localMaterials))
              
              inProgress.value = false
            }
          })
        })
        .catch(err => {
          inProgress.value = false
          console.log(err)
        })
    }

    const addMaterial = async (payload) => {
      await store
          .dispatch('app-material/addMaterial', { payload })
          .then(response => {
            // Remove Local Date Time
            localStorage.removeItem('last-loaded');
            // Item ID
            props.data.id = response.id
            uploadImage(0, () => {
              if (props.data.images !== undefined && props.data.images !== null && props.data.images.length > 0) {
                updateDBImages(response.id, true) 
              } else {
                tmpImages.value = []
                fetchNewMaterial(response.id)
              }
            })
          })
          .catch(err => {
            inProgress.value = false
            console.log(err)
          })
    }

    const addOtherToLocal = async (addother, payload, thedata) => {
      await store
        .dispatch('app-material/addOther', props.data.subtype)
        .then(response => {
          const oSubtype = {
            itemType: 'Other',
            description: `--- ${props.data.subtype}`,
            itemSubtype: props.data.subtype,
            image: '',
          }
          let otherSubtypes = JSON.parse(localStorage.getItem('other-subtypes'))
          if (otherSubtypes === null) {
            otherSubtypes = []
          }
          otherSubtypes.push(oSubtype)
          // Add back to local
          localStorage.setItem('other-subtypes', JSON.stringify(otherSubtypes))
          // Add Material
          if (addother) {
            addMaterial(payload)
          } else {
            upMaterial(thedata, payload)
          }
        })
        .catch(err => {
          inProgress.value = false
          console.log(err)
        })
    }

    const update = async data => {
      const isFormValid = materialForm.value.validate()
      if (isFormValid && props.data.location !== undefined && props.data.location !== null) {
        const payload = { ...data }
        console.log(payload)

        inProgress.value = true
        
        if (showOtherField.value) {
          addOtherToLocal(false, payload, data)
        } else {
          upMaterial(data, payload)
        }
      }
    }

    const add = async (data) => {
      const isFormValid = materialForm.value.validate()
      if (isFormValid && props.data.subtype && props.data.type) {
        if (data.need_by === undefined || data.need_by === null) {
          data.need_by = dateNeedBy
        }

        const payload = { ...data }
        payload.published = true
        payload.published_on = moment().format('MM/DD/yyyy hh:mma')

        console.log(payload)

        inProgress.value = true

        if (showOtherField.value) {
          addOtherToLocal(true, payload)
        } else {
          addMaterial(payload)
        }
      }
    }

    const setPlace = e => {
      props.data.location = e
      props.data.address = e.formatted_address
    }

    const onUpload = async imageData => {
      await store
        .dispatch('app-material/uploadImage', { image: imageData, itemId: props.data.id })
        .then(response => {
          if (props.data.images === undefined || props.data.images === null) {
            props.data.images = []
          }

          // Add to the array
          props.data.images.push(response)

          // Refresh Container
          imageContainer.value = props.data.images

          // console.log(props.data.images)

          // Update DB
          update(props.data)
        })
        .catch(err => {
          inProgress.value = false
          console.log(err)
        })
    }

    const fetchPhotos = () => {
      inputFile.value.click()
    }

    const previewImages = event => {
      // inProgress.value = true
      const imageData = event.target.files[0]
      tmpImages.value.push(event.target.files[0])
      // Refresh Container
      imageContainer.value.push(URL.createObjectURL(imageData))
    }

    const removeImage = async index => {
      const imgUrl = props.data.images[index]

      console.log(index)
      console.log(props.data.images)
      console.log(imgUrl)

      if (tmpImages.value.length > 0) {
        tmpImages.value.splice(index, 1)
        // Refresh Container
        imageContainer.value.splice(index, 1)
      } else {
        inProgress.value = true
      
        await store
          .dispatch('app-material/deleteImage', imgUrl)
          .then(response => {
            inProgress.value = false
            if (response.success) {
              // Update images
              props.data.images.splice(index, 1)
              update(props.data)
            }
          })
          .catch(err => {
            inProgress.value = false
            console.log(err)
          })
      }
      
    }

    const checkImages = objData => {
      if (objData.images && objData.images.length >= 3) {
        return true
      }

      return false
    }

    const whichImage = () => {
      if (materialTypeImage.value !== null) {
        return require(materialTypeImage.value)
      }
      return null;
    }

    const preloadImages = () => {
      // Image Container
      if (props.data.id) {
        const localMaterials = JSON.parse(localStorage.getItem('materials-list'))
        const itemIndex = localMaterials.findIndex(m => m.id === props.data.id)
        if (itemIndex >= 0) {
          if (localMaterials[itemIndex].images && localMaterials[itemIndex].images.length > 0) {
            imageContainer.value = localMaterials[itemIndex].images
          }
          const materialIndex = itemTypes.findIndex(m => m.itemSubtype === localMaterials[itemIndex].subtype)
          materialType.value = itemTypes[materialIndex].itemSubtype
          if (itemTypes[materialIndex].image !== null && itemTypes[materialIndex].image !== undefined && itemTypes[materialIndex].image !== '') {
            materialTypeImage.value = itemTypes[materialIndex].image
          }
        }
      }
    }

    // Other materials
    let otherSubtypes = JSON.parse(localStorage.getItem('other-subtypes'))
    if (otherSubtypes == null) {
      store
        .dispatch('app-material/fetchOthers')
        .then(response => {
          otherSubtypes = response
          localStorage.setItem('other-subtypes', JSON.stringify(response))

          itemTypes = itemTypes.concat(response)
          preloadImages()
        })
        .catch(err => {
          inProgress.value = false
          console.log(err)
        })
    } else {
      itemTypes = itemTypes.concat(otherSubtypes)

      preloadImages()
    }

    return {
      isIssueDateMenuOpen,
      isDueDateMenuOpen,
      clients,
      postTypes,
      update,
      add,
      inProgress,
      errorMessages,
      materialForm,
      showPhone,
      showEmail,
      setPlace,
      fetchPhotos,
      images,
      previewImages,
      inputFile,
      removeImage,
      checkImages,
      imageContainer,
      materialType,
      materialTypeResults,
      itemTypes,
      contactTypes,
      selectedMaterial,
      selectedContact,
      dateNeedBy,
      showDatePicker,
      metricTypes,
      selectedMetric,
      postTypes,
      selectedPosting,
      machineOptions,
      selectedMachine,
      truckOptions,
      selectedTrucking,
      materialTypeImage,
      whichImage,
      imageDialogVisible,
      materialPicked,
      dateField,
      showOtherField,
      otherSubtypeField,
      modifyOtherSubtype,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      validators: {
        required,
        requiredSelect,
      },

      // Icons
      icons: {
        mdiClose,
        mdiCogOutline,
        mdiCalendar,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/apps/invoice.scss';

.autocomplete-results {
  border: 0.5px solid;
  padding: 0px;
}
.autocomplete-result {
  list-style: none;
  display: flex;
  padding: 5px;
  height: 50px;
}
.autocomplete-result:hover {
  background-color: #9cafb7;
  color: white;
}
.autocomplete-thumb {
  width: 40px;
  padding: 5px;
}
.autocomplete-text {
  position: relative;
  top: 25%;
}
.material-editable {
  padding: 25px;
}
.image-preview {
  padding-top: 10px;
}
.image-preview>img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 15px;
}
.preview {
  max-width: 400px;
  max-height: 400px;
}
</style>
